// Entry point for the build script in your package.json
import "@hotwired/turbo-rails";
import "./controllers";


document.addEventListener("turbo:load", () => {
  
  const showAccounts = document.getElementById('show-accounts');
  const accountList = document.getElementById('account-list');

  if (showAccounts && accountList) {
    showAccounts.addEventListener('dblclick', () => {
      accountList.classList.toggle('hidden');
    });

    showAccounts.addEventListener('click', () => {
      accountList.classList.add('hidden');
    });
  }
});

document.addEventListener("turbo:before-cache", () => {
  
  const showAccounts = document.getElementById('show-accounts');
  const accountList = document.getElementById('account-list');

  if (showAccounts && accountList) {
    showAccounts.removeEventListener('dblclick', () => {
      accountList.classList.add('hidden');
    });

    accountList.removeEventListener('click');
  }
});
